import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {toLower, toUpper} from 'lodash';
import axios,{post,get,patch} from 'axios';
import StatusSteps from '../../Helper/StatusSteps';
import Vendor from './views/vendor';
import Mandapam from './views/mandapam';
import Photos from './views/photos';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config';
import empty from 'is-empty'
import Breadcrumbs from '../../Helper/Breadcrumbs'
import ErrorHandling from '../../Helper/ErrorHandling';
import Options from './views/options';
import BlockDates from './views/blockDates';
import {FaChevronCircleDown} from 'react-icons/fa'
import {Popover,Typography} from '@mui/material'
import ViewQRCode from './views/ViewQRCode';
import UploadVideos from './views/uploadVideos'

const VendorForm = (props) => {
  let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  let isProfile = document.URL.includes('profile') ? true : false;
  let propsState = isProfile ? {id : JSON.parse(sessionStorage.getItem('permission'))[0].vendor_ids[0]} : props.history.location.state;
  const [indexVal, setIndexVal] = useState(0);
  const [form, setForm] = useState({});
  const [mandap, setMandap] = useState({});
  const [images, setImages] = useState([]);
  const [editMode, setEditMode] = useState(propsState && propsState.editMode || false);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [handleErr, sethandleErr] = useState(false);
  const [errData, seterrData] = useState(null);
  const [currTab, setCurrTab] = useState('vendor');
  const [showOptions, setshowOptions] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [showCal, setshowCal] = useState(false);
  const [showQRCode, setshowQRCode] = useState(false);

  let breadcrumbData = isProfile ? [{
    title : 'Vendor Profile',
    href : '#'
  }] : propsState && propsState.tabValue ? [
   {
    title : 'Mandap',
      href : '/mandapList',
      state : {
        id: propsState && propsState.vendor_id ,
      }
    },
    {
      title : editMode ? 'View' :  'Create',
      href : '#'
    }
  ]
: [
    {
      title :  'Vendor',
      href : '/vendor',
      state : {
        id: propsState && propsState.vendor_id ,
      }
    },
    {
      title : editMode ? 'View' :  'Create',
      href : '#'
    }
  ]

  let isAdminUser = sessionStorage.getItem('permission') ? JSON.parse(sessionStorage.getItem('permission'))[0].user_role === 'admin' || false : false;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
    let mapIndex = {
      vendor:0,
        mandapam:1,
        photos:2,
        videos : 3
    }
  
  const [tab, setTab] = useState({
      vendor:false,
      mandapam:false,
      photos:false,
      videos : false
  });
    const [tabData, setTabData] = useState([
        {
            name:'Details',
            id:'vendor',
            show:false
        },
        {
            name:'Mandap Details',
            id:'mandapam',
            show:false
        },
        {
            name:'Photos',
            id:'photos',
            show:false
        },
        {
          name:'Videos',
          id:'videos',
          show:false
        }
    ]);
    const handleTab = (e,index)=>{
        let tabValue = e.target.id;
        setIndexVal(index);
        setTab({
            vendor:false,
      mandapam:false,
      photos:false,
      videos : false,
      [tabValue] : true
        })
    };

    const allocationPopover = (event) => {
      setAnchor(event.currentTarget);
  };

    const setFormDetails = (data,keyName) =>{
        setForm({
            ...form,
            ...data
        });
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }

    const setMandapDetails = (data,keyName) =>{
        setMandap({
            ...mandap,
            ...data
        });
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }

    const setPhotoDetails = (data) =>{
        setImages(data);
    }

    const saveMap = data => {
      setMandap({
        ...mandap,
        latitude : data.lat,
        longitude : data.lng
      })
    }

  const renderListView = () => {
      // if(tab.mandapam) 
      renderMandapamList()
      // else history.push('/vendor')
  }
  const renderMandapam = () => {
    tabData.forEach(function (obj) {
        if(obj.id === 'mandapam') obj.show = true;
        else obj.show = false;
    });
    setTabData(tabData);
    setTab({
        vendor:false,
        mandapam:true,
        photos:false,
        videos : false
    });
  }
  const renderMandapamList = () => {
    history.push({
        pathname:'/mandapList',
    })
  }
  const findVendorErrors = () => {
    const { vendor_name,owner_phone,owner_name, address_1, category_id, type_of_concern_id,contact_email, owner_aadhar,owner_PAN,enrollment_form,cancelled_cheque } = form
    const newErrors = {}
    if ( empty(vendor_name) ) newErrors.vendor_name = 'This is a required field';
    if ( empty(owner_phone) ) newErrors.owner_phone = 'This is a required field';
    // if(propsState && propsState.approval){
    // if ( empty(address_1) ) newErrors.address_1 = 'This is a required field';
    // if ( empty(category_id) ) newErrors.category_id = 'This is a required field';
    // if ( empty(type_of_concern_id) ) newErrors.type_of_concern_id = 'This is a required field';
    // if ( empty(contact_email) ) newErrors.contact_email = 'This is a required field';
    // if ( empty(owner_name) ) newErrors.owner_name = 'This is a required field';
    // if ( empty(owner_aadhar) ) newErrors.owner_aadhar = 'This is a required field';
    // if ( empty(owner_PAN) ) newErrors.owner_PAN = 'This is a required field';
    // if ( empty(enrollment_form) ) newErrors.enrollment_form = 'This is a required field';
    // if ( empty(cancelled_cheque) ) newErrors.cancelled_cheque = 'This is a required field';
    // }
    return newErrors;
   }
   const findMandapErrors = () => {
    const {address_1, mandap_name,seating_capacity,dining_capacity,car_parking_capacity,bike_parking_capacity,no_of_rooms,food_type,ac_type, mandap_classification_id,mandap_type_id } = mandap
    const newErrors = {}
    if ( empty(address_1) ) newErrors.address_1 = 'This is a required field';
    if ( empty(mandap_name) ) newErrors.mandap_name = 'This is a required field';
    if ( empty(seating_capacity) ) newErrors.seating_capacity = 'This is a required field';
    if ( empty(dining_capacity) ) newErrors.dining_capacity = 'This is a required field';
    if ( empty(car_parking_capacity) ) newErrors.car_parking_capacity = 'This is a required field';
    if ( empty(bike_parking_capacity) ) newErrors.bike_parking_capacity = 'This is a required field';
    if ( empty(ac_type) ) newErrors.ac_type = 'This is a required field';
    if ( empty(food_type) ) newErrors.food_type = 'This is a required field';
    // if ( empty(no_of_rooms) ) newErrors.no_of_rooms = 'This is a required field';
    if ( empty(mandap_classification_id) ) newErrors.mandap_classification_id = 'This is a required field';
    if ( empty(mandap_type_id) ) newErrors.mandap_type_id = 'This is a required field';


    return newErrors;
   }
   const findPhotosErrors = () => {
    let newErrors = false;
    for(let i=0;i<images.length;i++){
      if(empty(images[i].image_name) || empty(images[i].image_category_id)){
          newErrors = true;
          break;
      }
  }
    return newErrors;
   }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    
    async function getBase64(file) {
       return(await toBase64(file));
    }

    const initiateErrorHandler = (errData, tabName) => {
      seterrData(errData); 
      sethandleErr(true);
      setCurrTab(tabName);
    }

  const saveForm = (needScroll) => {
      let id =editMode ? propsState.id : null, method = editMode ? 'patch' : 'post';
    if(tab.vendor){
        const newErrors = findVendorErrors();
    if ( Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors)
      toast.error('Enter all required data to proceed', toastAttr);
    } else {
      setLoader(true);
        let url = editMode ? config.service+'/masters/vendor/'+id+'/' : config.service+'/masters/vendor/create/',
        postData = new FormData(), data=form;

        if(typeof data.owner_aadhar === 'string') delete data['owner_aadhar']
        if(typeof data.owner_PAN === 'string') delete data['owner_PAN']
        if(typeof data.enrollment_form === 'string') delete data['enrollment_form']
        if(typeof data.cancelled_cheque === 'string') delete data['cancelled_cheque']
        if(typeof data.authorized_sign === 'string') delete data['authorized_sign']

        for(let key in data){
            if(data.hasOwnProperty(key) && data[key] !== null)
            postData.append(key,data[key])
        }

        axios({
            url: url,
            method: method,
            headers: tokenKey,
            data: postData,
          }).then(res=>{
              setForm(res.data);
            if(editMode) {
              toast.success('Saved successfully', toastAttr);
            } 
            else{
                tabData.forEach(function (obj) {
                    if(obj.id === 'mandapam') obj.show = true;
                    else obj.show = false;
                });
                setTabData(tabData);
                setTab({
                    vendor:false,
                    mandapam:true,
                    photos:false,
                    videos : false
                        })
            }
            setLoader(false);
            if(needScroll) window.scrollTo(0, 0)
        }).catch(err=>{
          if(err.response.status === 401) window.location.href = '/'
          else initiateErrorHandler(err.response && err.response.data,'vendor')
            toast.error('Saving Failed', toastAttr);
            setLoader(false);
        })
    }
      }
      else if(tab.mandapam){
        const newErrors = findMandapErrors();
        if ( Object.keys(newErrors).length > 0 ) {
          setErrors(newErrors)
          toast.error('Enter all required data to proceed', toastAttr);
        } else {
          setLoader(true);
        let url = editMode ? config.service+'/masters/vendor_mandap/'+id+'/' : config.service+'/masters/vendor_mandap/create/'
        let data = {
            ...mandap,
            vendor_id : propsState && propsState.vendor_id,
            // latitude: mandap.latitude.toString(),
            // longitude:mandap.longitude.toString()
        };
        if(mandap.latitude) {data.latitude = mandap.latitude.toString();data.longitude=mandap.longitude.toString()}
        if(empty(mandap.entry_time)) data.entry_time = null;
        if(empty(mandap.exit_time)) data.exit_time = null;
        if(empty(mandap.hall_height)) data.hall_height = null;
        if(empty(mandap.primary_stage_dim_ft)) data.primary_stage_dim_ft = null;
        if(empty(mandap.secondary_stage_dim_ft)) data.secondary_stage_dim_ft = null;
        delete data['vendor_image_ids'];
        
        axios({
        url: url,
        method: editMode ? 'PUT' : method,
        headers: tokenKey,
        data: data,
      }).then(res=>{
        if(!editMode) setMandap(res.data)
            propsState = {
                ...propsState,
                mandap_id : editMode ? mandap.id : res.data.id,
            }
            // setImages(mandap.vendor_image_ids || []);
        tabData.forEach(function (obj) {
            if(obj.id === 'photos') obj.show = true;
            else obj.show = false;
        });
        // setTabData(tabData);
        // setTab({
        //     vendor:false,
        //     mandapam:false,
        //     photos:true,
        //         })
                setLoader(false);
                toast.success('Saved successfully', toastAttr);		
                if(!editMode) setshowOptions(true);
              if(needScroll) window.scrollTo(0, 0)
      }).catch(err=>{
        if(err.response.status === 401) window.location.href = '/'
          else initiateErrorHandler(err.response && err.response.data,'mandapam');
            toast.error('Saving Failed', toastAttr);
            setLoader(false);
      })
    }
    }
      else if(tab.photos){
        const newErrors = findPhotosErrors();
        if ( newErrors ) {
          toast.error('Image name and category are required for all uploaded images', toastAttr);
        } else {
          setLoader(true);
          let url = editMode ? config.service+'/masters/vendor_mandap/'+id+'/' : config.service+'/masters/vendor_images/create/',
          photos = {
            vendor_mandap_id : mandap.id || propsState.mandap_id,
            mandap_images : []
          };
          images.map((obj) => {
            obj.vendor_mandap_id = mandap.id || propsState.mandap_id;
            photos.mandap_images.push(obj);
         })
        
            let postData = { mandap_images : photos.mandap_images}
            if(editMode) postData = {
                ...mandap,
                vendor_image_ids : photos.mandap_images
            }
            if(!editMode && empty(photos.mandap_images)) {
              setLoader(false);
              toast.success('Saved successfully', toastAttr);	
              setTimeout(() =>  renderListView(), 1000);
              return false;
            }
        axios({
            url: url,
            method: editMode ? 'PUT' : method,
            headers: {
                "Authorization": 'Token '+sessionStorage.getItem('tokenKey'),
                // "Content-type": "multipart/form-data",
            },  
            data: postData,
          }).then(res=>{
            setLoader(false);
            toast.success('Saved successfully', toastAttr);	
            setTimeout(() =>  renderListView(), 1000);
          }).catch(err=>{
            setLoader(false);
            if(err.response.status === 401) window.location.href = '/'
            else  initiateErrorHandler(err.response && err.response.data,'photos')
            toast.error('Saving Failed', toastAttr);
          })
        }
      }
  }

  const closeErrorHandling = () => {
    sethandleErr(false)
    seterrData(null);
  }

  const proceedToImages = () => {	
    setTab({	
      vendor:false,	
      mandapam:false,	
      photos:true,	
      videos : false
  });	
  tabData.forEach(function (obj) {	
    if(obj.id === 'photos'|| obj.id === 'videos') obj.show = true;	
    else obj.show = false;	
  });	
  setTabData(tabData);	
  setshowOptions(false);	
  }	
  const proceedToPricing = () => {	
    history.push({	
      pathname : '/pricing',	
      state : {	
        data : mandap	
      }	
    })	
  }
  

  const getApprovalBtnValue = () => {
    let status = mandap.status, val = '',
        statusMap = {
            draft : 'Submit',
            rejected : 'Submit',
        };
        if(!isAdminUser && statusMap[status] !=='Submit')
          return false;
        else return statusMap[status];
  }

  const triggerApproval = (btnName) => {
    propsState.approval = true;
    const newErrors = findMandapErrors();
    if ( Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors);
      toast.error('Enter all required data to proceed', toastAttr);
    } else {
      let data = {
        btn_name : toLower(btnName),
        vendor_id : null,
        mandap_id : mandap.id
      }
      setLoader(true);
      post(config.service+'/masters/dynamic_button_action/',data,{headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}}).then(res=>{
        toast.success('Action perfomed successfully', toastAttr);
        if(tab.mandapam)
          get(config.service+'/masters/vendor_mandap/'+mandap.id+"/",{headers:tokenKey}).then(res=>{
            setMandap(res.data)
            setLoader(false);
          });
      }).catch(err=>{
        if(err.response.status === 401) window.location.href = '/'
        toast.error('Action failed', toastAttr);
        setLoader(false);
      });
    }
  }

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }

  const handleClose = () => {
    setAnchor(null)
  }

  useEffect(() => {
          let keyVal = propsState && propsState.tabValue || 'vendor';
        setTab({
            vendor:false,
            mandapam:false,
            photos:false,
            videos : false,
            [keyVal] : true
        });
        tabData.forEach(function (obj) {
            if(obj.id === keyVal) obj.show = true;
            else obj.show = false;
        });
        setTabData(tabData);
        // setLoader(false)
  }, []);
let BtnLabel = 'Next';
    if(editMode && (tab.vendor || tab.photos)) BtnLabel = 'Save';
    else if(!editMode && tab.photos) BtnLabel = 'Save';
  let approvalBtn = getApprovalBtnValue();
  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody className='theme-light'>
        <div className="card__title pageNav">
        {/* change heading */}
          <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>  
        </div>
        {!isProfile ? 
        <Row className='formBtnsDiv'>
            <Col md={6} xs={7}>
              {!tab.videos ? <Button variant='primary' onClick={e=>{saveForm()}}>{BtnLabel}</Button> : ''}
              <Button variant='secondary' onClick={e=>{renderListView()}}>Cancel</Button>
            </Col>
            {tab.mandapam && editMode && !loading ? <Col md={2} xs={5}>
              
              <div className="userDetails" style={{ width:'max-content', cursor:'pointer'}} aria-describedby='simple-popover' variant="contained" onClick={allocationPopover}>
              <div className='formActions'>Actions <span><FaChevronCircleDown color='#ec2d7c' /></span></div>
                </div>
                <Popover open={Boolean(anchor)} anchorEl={anchor} id='simple-popover'
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}>
                    <Typography variant="">
                        <div className='userPopover'>
                          <div onClick={()=>setshowCal(true)}>Allocate Mandap</div>
                        </div>
                    </Typography>
                </Popover>
            </Col> : ''}
        </Row> : ''}
      {showCal ? <BlockDates handleClose={()=>{setshowCal(false);handleClose();}} mandapId={mandap.id} />: ''}
        <Row className='formBtnsDiv'>
            <Col md={12} style={{margin:'5px 0'}}>
    {editMode && approvalBtn  ? <Button variant='primary' onClick={e=>{triggerApproval(approvalBtn)}}>{approvalBtn}</Button>  : ''}
    {/* {editMode && tab.vendor ?<div style={{paddingTop:'6px',display:'inline-block', cursor:'pointer'}}> <a className='' onClick={e=>{renderMandapamList()}}>View Mandap</a></div> : ''} */}
    {editMode ? <StatusSteps data={{status: mandap.status}}  />  : ''}
            </Col>
        </Row>
        <Row className='formDiv'>
            <Col md={8}>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <div className='tabList'>
                        {tabData.map((tabObj,index)=>{
                                let disCls = tabObj.disable;
                                let keys = Object.keys(tab).filter(k => tab[k])
                                return tabObj.show ? (
                                  <>
                                    <a className={`nav-item nav-link ${mapIndex[keys[0]] === index ? 'active' : ''}`} 
                                    id={tabObj.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'
                                    onClick={e=> handleTab(e,index)} >
                                        {tabObj.name}
                                    </a>
                                    </>
                                ) : '';
                            })}
                        </div>
                    </div>
                </nav>
              </Col>
              {editMode && tab.mandapam && mandap.id ?	
            <> 	
              <Col md={4} className="navigations">	
                <div className='softButton' onClick={proceedToPricing}><span>UPDATE PRICING</span></div>	
                <div className='softButton' onClick={proceedToImages}><span>UPLOAD IMAGES</span></div>	
              </Col>	
            </> : ''}
            {isProfile && form.id  ? 
            <Col md={4} className="navigations">
            <div className='softButton' onClick={e=>setshowQRCode(true) }><span>SCAN QRCODE</span></div></Col>
            : ''}
              <Col md={12}>
                <div className='tabContent'>
                    <Form className="form defaultForm" >
                        {tab.vendor ? <Vendor form={form} propsData={propsState} loading={loading} overlay={overlay} save={setFormDetails} isProfile={isProfile} errors={errors} setLoader={setLoader} />  : ''}
                        {tab.mandapam ? <Mandapam mandap={mandap} propsData={propsState} loading={loading} overlay={overlay} save={setMandapDetails} errors={errors} setLoader={setLoader} saveMap={saveMap} /> : ''}
                        {tab.photos ? <Photos images={images} propsData={propsState} loading={loading} overlay={overlay} save={setPhotoDetails} mandapId={mandap.id}  errors={errors} setLoader={setLoader} />   : ''}
                        {tab.videos ? <UploadVideos loading={loading} overlay={overlay}  mandapId={mandap.id}  setLoader={setLoader} />   : ''}
                    </Form>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={12} style={{textAlign:'center'}}>
              {!tab.videos ?   <Button variant='primary' onClick={e=>{saveForm(true)}}>{BtnLabel}</Button> : ''}
                <Button variant='secondary' onClick={e=>{renderListView()}}>Cancel</Button>
            </Col>
        </Row>
        {showQRCode ? <ViewQRCode handleClose={e=>setshowQRCode(false)} data={form} /> : ''}
        {handleErr ? <ErrorHandling title={currTab} closeErrorHandling={closeErrorHandling} error={errData} /> : ''}
        {showOptions ? <Options data={mandap}  handleClose={e=>setshowOptions(false)} proceedToImages={proceedToImages} /> : ''}
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default VendorForm;
