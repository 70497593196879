import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Badge, ListGroup } from "react-bootstrap";
import { IoPricetags } from "react-icons/io5";
import { BiRupee } from "react-icons/bi";
import { FaUtensils, FaPercent } from "react-icons/fa";
import { MdLocationOn, MdOutlineCollectionsBookmark } from "react-icons/md";
import ImageStack from "./ImageStack";

const PricingDetails = ({ tableData }) => {
  const [includeItems, setIncludeItems] = useState([]);
  const [excludeItems, setExcludeItems] = useState([]);
  const [mandap, setMandap] = useState(null);

  useEffect(() => {
    if (tableData) {
      console.log(tableData);
      if (tableData.mandap_id) {
        setMandap(tableData?.mandap_id[0]);
      }
      const parsedInclPrice = JSON.parse(tableData.incl_price || "[]");

      console.log();

      const filteredIncludeItems = parsedInclPrice.filter((item) =>
        tableData.include_list?.some((inc) => inc.id === item.id)
      );

      const filteredExcludeItems = parsedInclPrice.filter((item) =>
        tableData.exclude_list?.some((exc) => exc.id === item.id)
      );

      // Update the state with the filtered results
      setIncludeItems(filteredIncludeItems);
      setExcludeItems(filteredExcludeItems);
      // }
    }
  }, [tableData]);

  if (!tableData) {
    return (
      <Container className="mt-3">
        <Card className="text-center p-3">
          <Card.Body>No data found</Card.Body>
        </Card>
      </Container>
    );
  }

  return (
    <Container className="mt-3">
      <Card>
        <Card.Header style={{ backgroundColor: "#ee448b", color: "white" }}>
          <div className="d-flex align-items-center">
            <IoPricetags className="me-2" />
            <h5 className="mb-0 ml-2 text-white">Price Details</h5>
          </div>
        </Card.Header>
        <Card.Body>
          {mandap !== null && (
            <Row className="mb-3">
              <Col md={6}>
                <h4 className="bold">Venue Information</h4>
                <p>
                  <strong>{mandap.mandap_name}</strong> -{" "}
                  {mandap.mandap_type_name}
                </p>
                <p className="d-flex align-items-center">
                  <MdLocationOn className="me-1" />
                  {mandap.address_1}, {mandap.area_name}, {mandap.city_name}
                </p>
                {mandap.has_discount && (
                  <Badge bg="success" className="me-2">
                    <FaPercent className="me-1" />
                    {mandap.discount_percent}% Discount Available
                  </Badge>
                )}
                {tableData.bwg_promise && (
                  <Badge bg="primary">BWG Promise</Badge>
                )}
              </Col>
              <Col>
                <Row>
                  <Col md={4}>
                    <h4>Menu</h4>
                    <ImageStack images={tableData.images} />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <Row className="mb-3">
            <Col md={12}>
              <h4>Reservation Price</h4>
              <p className="d-flex align-items-center fs-4">
                <BiRupee />
                {tableData.reservation_price || "N/A"}
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Card className="mb-3">
                <Card.Header className="bg-light">
                  <h5 className="mb-0 d-flex align-items-center">
                    <MdOutlineCollectionsBookmark />
                    <div className="ml-2">Booking Options</div>
                  </h5>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h6 className="font-weight-bold">Full Day</h6>
                    <p className="mb-0">
                      Price: <BiRupee />
                      {tableData.fd_price || "N/A"}
                    </p>
                    <p className="mb-0">
                      Timing: {tableData.fd_start_time} -{" "}
                      {tableData.fd_end_time || "N/A"}
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h6 className="font-weight-bold">Half Day (Morning)</h6>
                    <p className="mb-0">
                      Price: <BiRupee />
                      {tableData.hf_am_price || "N/A"}
                    </p>
                    <p className="mb-0">
                      Timing: {tableData.hf_am_start_time} -{" "}
                      {tableData.hf_am_end_time || "N/A"}
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h6 className="font-weight-bold">Half Day (Evening)</h6>
                    <p className="mb-0">
                      Price: <BiRupee />
                      {tableData.hf_pm_price || "N/A"}
                    </p>
                    <p className="mb-0">
                      Timing: {tableData.hf_pm_start_time} -{" "}
                      {tableData.hf_pm_end_time || "N/A"}
                    </p>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-3">
                <Card.Header className="bg-light">
                  <h5 className="mb-0 d-flex align-items-center">
                    <FaUtensils />
                    <div className="ml-2">Meals (per plate)</div>
                  </h5>
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h6 className="font-weight-bold">Breakfast</h6>
                    <p className="mb-0">
                      Veg: <BiRupee />
                      {tableData.pr_breakfast_vg || "N/A"}
                    </p>
                    <p className="mb-0">
                      Non-veg: <BiRupee />
                      {tableData.pr_breakfast_nvg || "N/A"}
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h6 className="font-weight-bold">Lunch</h6>
                    <p className="mb-0">
                      Veg: <BiRupee />
                      {tableData.pr_lunch_vg || "N/A"}
                    </p>
                    <p className="mb-0">
                      Non-veg: <BiRupee />
                      {tableData.pr_lunch_nvg || "N/A"}
                    </p>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <h6 className="font-weight-bold">Dinner</h6>
                    <p className="mb-0">
                      Veg: <BiRupee />
                      {tableData.pr_dinner_vg || "N/A"}
                    </p>
                    <p className="mb-0">
                      Non-veg: <BiRupee />
                      {tableData.pr_dinner_nvg || "N/A"}
                    </p>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Card>
                <Card.Header className="bg-light">
                  <h5 className="mb-0">Included Items</h5>
                </Card.Header>
                <ListGroup variant="flush">
                  {includeItems.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <span>
                        {
                          tableData.include_list.find(
                            (inc) => inc.id === item.id
                          )?.name
                        }
                      </span>
                      <Badge
                        bg="#ee448b"
                        style={{ backgroundColor: "#ee448b", color: "white" }}
                        pill
                        className="py-2 px-4 fs-6"
                      >
                        <BiRupee />
                        {item.price}{" "}
                        {tableData.include_list.find(
                          (inc) => inc.id === item.id
                        )?.unit && (
                          <>
                            /{" "}
                            {
                              tableData.include_list.find(
                                (inc) => inc.id === item.id
                              )?.unit
                            }
                          </>
                        )}
                      </Badge>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Header className="bg-light">
                  <h5 className="mb-0">Excluded Items</h5>
                </Card.Header>
                <ListGroup variant="flush">
                  {excludeItems.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <span>
                        {
                          tableData.exclude_list.find(
                            (exc) => exc.id === item.id
                          )?.name
                        }
                      </span>
                      <Badge
                        bg="#ee448b"
                        style={{ backgroundColor: "#ee448b", color: "white" }}
                        pill
                        className="py-2 px-4 fs-6"
                      >
                        <BiRupee />
                        {item.price}{" "}
                        {tableData.exclude_list.find(
                          (exc) => exc.id === item.id
                        )?.unit && (
                          <>
                            /{" "}
                            {
                              tableData.exclude_list.find(
                                (exc) => exc.id === item.id
                              )?.unit
                            }
                          </>
                        )}
                      </Badge>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PricingDetails;
