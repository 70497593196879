import React, { useState, useRef } from "react";
import { Modal, Button, Stack, Image, Row, Col, Container } from "react-bootstrap";
import { BiTrash } from "react-icons/bi";

const ImageStack = ({
  images = [],
  onImageRemove,
  onImageAdd,
  edit = false
}) => {
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = useRef(null);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleAddImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const renderImageGrid = () => {
    const firstThreeImages = images.slice(0, 3);
    const remainingCount = images.length - 3;

    return (
      <Row className="m-0">
        {firstThreeImages.map((image, index) => (
          <Col key={index} xs={6} className="p-0">
            <div style={{ aspectRatio: "1", width: "100%" }}>
              <Image
                src={image}
                alt={`Image ${index + 1}`}
                className="w-100 h-100"
                style={{ 
                  objectFit: "cover",
                  cursor: "pointer"
                }}
                onClick={handleShow}
              />
            </div>
          </Col>
        ))}

        {remainingCount > 0 && (
          <Col xs={6} className="p-0">
            <div 
              style={{ 
                aspectRatio: "1",
                width: "100%",
                backgroundColor: "#f8f9fa",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={handleShow}
            >
              <span style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#6c757d" }}>
                +{remainingCount}
              </span>
            </div>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <Container fluid className="p-0">
      {images.length > 0 ? (
        renderImageGrid()
      ) : (
        <div
          onClick={handleAddImageClick}
          style={{
            aspectRatio: "1",
            maxWidth: "200px",
            border: "2px dashed #dee2e6",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "#f8f9fa"
          }}
        >
          <span className="text-muted">Add Images</span>
        </div>
      )}

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>All Images</Modal.Title>
          { (
            <Button 
              variant="light" 
              onClick={handleClose}
              className="p-1 ms-auto"
              style={{ 
                width: "32px", 
                height: "32px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              ×
            </Button>
          )}
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <Row>
            {images.map((image, index) => (
              <Col key={index} xs={12} sm={6} md={4} className="mb-3">
                <div style={{ aspectRatio: "1", width: "100%" }}>
                  <Image
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="w-100 h-100"
                    style={{ objectFit: "cover" }}
                  />
                  {edit && (
                    <Button
                      variant="danger"
                      size="sm"
                      style={{cursor:"pointer"}}
                      // className="position-absolute top-0 end-0 m-2"
                      onClick={() => onImageRemove(index)}
                    >
                      <BiTrash/>
                    </Button>
                  )}
                </div>
              </Col>
            ))}
          </Row>
          
          {edit && (
            <div className="mt-3">
              <Button variant="primary" onClick={handleAddImageClick}>
                Add Image
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={onImageAdd}
        accept="image/*"
      />
    </Container>
  );
};

export default ImageStack;